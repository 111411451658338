<template>
    <div style="width: 100%;height: 36px;text-align: center;">
        <el-pagination style="float: right;" background @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage" :page-sizes="[20, 50, 100]" :page-size="pageSize"
                       layout="total, sizes, prev, pager, next" :total="total"></el-pagination>
    </div>
</template>

<script>
    export default {
        name: "publicPage",
        data() {
            return {
                currentPage: 1,
                pageSize: 20,
                total: 0,
            }
        },
        // props: ['publicPageTotal'],
        emits: ['pageChange'],
        props: {
            publicPageTotal: [Object],
        },
        watch: {
            publicPageTotal(val) {
                this.total = val.total * 1;
            },
        },
        methods: {
            handleSizeChange(val) {
                this.currentPage = 1;
                this.pageSize = val;
                this.$emit('pageChange', '1');
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.$emit('pageChange', '1');
            },
        }
    }
</script>

<style scoped>

</style>
